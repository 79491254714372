'use client'

import clsx from 'clsx'

import { Table } from '@wartek-id/table'
import { Text } from '@wartek-id/text'

interface KeyValTableProps {
  rows: {
    key: string
    displayName: string
    value: string | number
    CustomValueComponent?: ({ value }) => JSX.Element
    classNames?: {
      key: string
      value: string
    }
  }[]
  classNames?: {
    table?: string
    body?: string
    row?: string
    colKey?: string
    colVal?: string
  }
  bordered?: boolean
}
export function KeyValTable({
  rows,
  classNames = { table: '', body: '', row: '', colKey: '', colVal: '' },
  bordered = false,
}: KeyValTableProps) {
  const classTable = classNames?.table || ''
  const classBody = classNames?.body || ''
  const classRow = classNames?.row || ''
  const classColKey = classNames?.colKey || ''
  const classColVal = classNames?.colVal || ''

  return (
    <div className="overflow-auto pb-4">
      <Table
        variant="basic"
        bordered={bordered}
        size="sm"
        className={clsx(classTable)}
        rounded
      >
        <tbody className={clsx(classBody)}>
          {(rows || []).map((item, indexRow) => (
            <tr
              key={item.key}
              className={clsx(`row--${indexRow}`, classRow)}
              data-key={item.key}
            >
              <td
                className={clsx(
                  `col--0 align-top`,
                  !bordered && '!border-0',
                  classColKey,
                  item?.classNames?.key
                )}
              >
                {item.displayName}
              </td>
              <td
                className={clsx(`col--1 align-top`, !bordered && '!border-0')}
              >
                :
              </td>
              <td
                className={clsx(
                  `col--2 align-top`,
                  !bordered && '!border-0',
                  classColVal,
                  item?.classNames?.value
                )}
                data-testid={`txt-value-${item.key || indexRow}`}
              >
                {item.CustomValueComponent ? (
                  <item.CustomValueComponent value={item.value} />
                ) : (
                  <Text>{item.value}</Text>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}
