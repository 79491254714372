'use client'

import { ASSET_PREFIX, HOME_ROUTE } from '@/configs/constants'

const LOGO_PREFIX_URL =
  '/images/logo-portal-data-kemendikbudristek-primary-small'

export const HeaderLogoNew = () => {
  return (
    <a className="flex items-center gap-2 py-2" href={HOME_ROUTE}>
      <picture>
        <source
          type="image/webp"
          srcSet={`${ASSET_PREFIX}/images/logo-kemdikbud.webp`}
        />
        <source
          type="image/png"
          srcSet={`${ASSET_PREFIX}/images/logo-kemdikbud.png`}
        />
        <img
          src={`${ASSET_PREFIX}/images/logo-kemdikbud.png`}
          alt="Kemendikbud"
          className="h-[42px] w-[42px] max-w-[42px]"
          height={42}
          width={42}
        />
      </picture>
      <picture>
        <source
          type="image/webp"
          srcSet={`${ASSET_PREFIX}${LOGO_PREFIX_URL}.webp`}
        />
        <source
          type="image/png"
          srcSet={`${ASSET_PREFIX}${LOGO_PREFIX_URL}.png`}
        />
        <img
          src={`${ASSET_PREFIX}${LOGO_PREFIX_URL}.png`}
          alt="Portal Data Kemendikbudristek"
          className="h-[34px] w-auto max-w-[150px]"
          height={34}
          width={98}
        />
      </picture>
    </a>
  )
}
