'use client'

import style from './header-menu.module.css'

import clsx from 'clsx'
import { useRef } from 'react'

import { Badge } from '@wartek-id/badge'
import { Icon } from '@wartek-id/icon'
import { List, ListItem } from '@wartek-id/list'
import { Popover, PopoverContent, PopoverTrigger } from '@wartek-id/popover'
import { Tab, TabList, Tabs } from '@wartek-id/tabs'
import { Text } from '@wartek-id/text'

import { FloatingWrapper } from './FloatingWrapper'
import { MenuPlatformPendukung } from './MenuPlatformPendukung'
import { getDataMenus } from './menus'
import { isActiveChildren, useActiveMenu } from './useActiveMenu'
import { Container } from '../Container'
import { LinkWithTracker } from '../LinkWithTracker'

export const HeaderMenuDesktopNew = ({
  currentPath,
}: {
  currentPath: string
}) => {
  const { activeIndex } = useActiveMenu({ currentPath })
  const activeItemRef = useRef(null)

  const menus = getDataMenus({ withPtk: true })

  return (
    <nav className="px-2">
      <Container removePadding={true}>
        <Tabs selectedIndex={activeIndex === 999 ? 5 : activeIndex}>
          <TabList>
            {menus.map((menu) => (
              <Tab
                key={menu.text}
                className={clsx(
                  menu.children
                    ? style.tabDesktopLinkWithChildren
                    : style.tabDesktopLink
                )}
                // @ts-ignore
                as={menu.children || menu.specialVisual ? 'div' : 'button'}
              >
                {menu.children ? (
                  <Popover placement={'bottom-end'} trigger="hover">
                    <PopoverTrigger className="popover-trigger">
                      <span
                        className={clsx(
                          'flex items-center gap-1',
                          style.tabDesktopMenuText
                        )}
                      >
                        <span data-testid={`menu-${menu.index}`}>
                          {menu.text}
                        </span>
                        <Icon>keyboard_arrow_down</Icon>
                      </span>
                    </PopoverTrigger>
                    <PopoverContent
                      style={{
                        marginTop: 8,
                        minWidth: 240,
                      }}
                      initialFocus={activeItemRef}
                    >
                      {menu.desc && (
                        <div className="mb-4 space-y-2 p-2">
                          {menu.descLogo ? menu.descLogo : null}
                          <Text variant="heading-md">{menu.text}</Text>
                          <Text className="max-w-[300px]">{menu.desc}</Text>
                        </div>
                      )}
                      <List type="menu" className="p-2">
                        {menu?.children?.map((childMenu) => {
                          const isActive = isActiveChildren({
                            currentPath,
                            parentMenu: menu,
                            childMenu,
                          })
                          return (
                            <ListItem
                              key={childMenu.text}
                              as="a"
                              href={childMenu.href}
                              data-testid={`child-menu-${childMenu.index}`}
                              iconRight={<Icon>chevron_right</Icon>}
                              ref={isActive ? activeItemRef : null}
                            >
                              <Text variant="body-bold">{childMenu.text}</Text>
                            </ListItem>
                          )
                        })}
                      </List>
                    </PopoverContent>
                  </Popover>
                ) : (
                  <>
                    {menu.specialVisual ? (
                      <Popover
                        placement={'bottom-end'}
                        trigger="hover"
                        data-testid={`popover-special`}
                        floatingAs={FloatingWrapper}
                      >
                        <PopoverTrigger className="popover-trigger">
                          <span
                            className={clsx(
                              'flex items-center gap-1',
                              style.tabDesktopMenuText
                            )}
                          >
                            <span data-testid={`menu-${menu.index}`}>
                              {menu.text}
                            </span>
                            <Icon>keyboard_arrow_down</Icon>
                          </span>
                        </PopoverTrigger>
                        <PopoverContent
                          focus={false}
                          style={{
                            marginTop: 8,
                          }}
                        >
                          <MenuPlatformPendukung />
                        </PopoverContent>
                      </Popover>
                    ) : (
                      <LinkWithTracker
                        href={menu.href}
                        passHref
                        prefetch={false}
                        className="flex items-center gap-2"
                        data-testid={`menu-${menu.index}`}
                      >
                        <span>{menu.text}</span>
                        {menu.new && (
                          <Badge variant="informational" size="sm">
                            Baru
                          </Badge>
                        )}
                      </LinkWithTracker>
                    )}
                  </>
                )}
              </Tab>
            ))}
          </TabList>
        </Tabs>
      </Container>
    </nav>
  )
}
