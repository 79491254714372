'use client'

import Link, { LinkProps } from 'next/link'

import { sendBatchDataToSatuEventApi } from '@/utils/tracker/satuTracker'

export const LinkWithTracker = (
  props: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> &
    LinkProps & {
      children?: React.ReactNode
    } & React.RefAttributes<HTMLAnchorElement>
) => {
  const handleClick = (event) => {
    sendBatchDataToSatuEventApi()

    if (typeof props.onClick === 'function') {
      props?.onClick(event)
    }
  }

  return (
    <Link {...props} onClick={handleClick}>
      {props.children}
    </Link>
  )
}
