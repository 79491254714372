'use client'

import { forwardRef } from 'react'

export const FloatingWrapper = forwardRef((props, ref) => {
  // @ts-ignore
  const { style = {}, ...rest } = props || { style: {} }
  const transform = style?.transform
  const translateValue = transform?.match(/translate\((-?\d+px),(-?\d+px)\)/)
  // Custom Override Value
  const translateX = '0px'
  const translateY = translateValue?.[2] || '0px'
  const newStyleTransform = `translate(${translateX},${translateY})`

  return (
    <div
      {...rest}
      style={{
        ...style,
        width: '100%',
        transform: newStyleTransform,
      }}
      className="floating-wrapper"
      // @ts-ignore
      ref={ref}
    />
  )
})

FloatingWrapper.displayName = 'FloatingWrapper'
