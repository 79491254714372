export const range = (start, stop, step = 1) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const chunk = (arr, chunkSize) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const chunkChronological = (arr, chunkSize) => {
  return arr.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index % chunkSize)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [] // start a new chunk
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])
}

export const mergePageData = (arr) => {
  return arr.reduce((resultArray, item) => {
    resultArray = [...resultArray, ...(item?.data || [])]

    return resultArray
  }, [])
}

export const getUniqueItemsByProperties = (items, propName) => {
  return items.filter(
    (v, i, a) => a.findIndex((v2) => v2[propName] === v[propName]) === i
  )
}
