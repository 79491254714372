'use client'

import clsx from 'clsx'
import { useState } from 'react'
import { useDoubleTap } from 'use-double-tap'

import { Icon } from '@wartek-id/icon'
import { Link } from '@wartek-id/link'
import { Text } from '@wartek-id/text'

import { scrollToTop } from '@/utils/dom-access'

import { UTM_SOURCE_FROM_PSD } from '@/configs/constants'

import { BackToTop } from '../BackToTop'
import { Container } from '../Container'
import { EasterEgg } from '../EasterEgg/EasterEgg'
import { LazyImg } from '../LazyImg'
import { LinkWithTracker } from '../LinkWithTracker'

const TAUTAN_TERKAIT = [
  {
    text: 'Kemdikbudristek',
    link: 'https://kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
  },
  {
    text: 'Pusdatin',
    link: 'https://pusdatin.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
  },
  {
    text: 'Referensi',
    link: 'https://referensi.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
  },
  {
    text: 'Rumah Belajar',
    link: 'http://rumahbelajar.id/?' + UTM_SOURCE_FROM_PSD,
  },
  {
    text: 'Sekolah Kita',
    link: 'https://sekolah.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
  },
  {
    text: 'Jendela Pendidikan',
    link: 'https://jendela.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
  },
]

export const FooterDefault = () => {
  const [clicked, setClicked] = useState(false)

  const bindDoubleTap = useDoubleTap(() => {
    setClicked((prev) => !prev)
  })

  const handleToTop = () => {
    scrollToTop()
  }

  return (
    <>
      <BackToTop />
      <footer className={clsx('relative bg-blue-10 text-white')}>
        <div className="h-[6px] w-full bg-orange-20"></div>
        <Container>
          <div className="mb-8 mt-8 flex flex-col justify-between gap-8 md:flex-row">
            {/* Left Section --start */}
            <div className="flex flex-col gap-6">
              <LinkWithTracker href="/">
                <LazyImg
                  src="/images/logo-portal-data-kemendikbudristek-primary.png"
                  alt="Portal Data Kemendikbudristek"
                  withWebp={true}
                  useCdn={true}
                  height={101}
                  width={230}
                  className="h-auto w-auto max-w-[230px] cursor-pointer"
                />
              </LinkWithTracker>
              <Text variant="body-sm" className="max-w-[320px]">
                Satu Data Pendidikan dan Kebudayaan adalah kebijakan tata kelola
                data untuk menghasilkan informasi yang akurat, mutakhir,
                terpadu, dapat dipertanggungjawabkan, diakses, dan
                dibagipakaikan.
              </Text>
            </div>
            {/* Left Section --end */}

            {/* Center Section --start */}
            <div className="flex flex-col gap-6">
              <Text variant="heading-md">Hubungi Kami</Text>
              <div className="space-y-2">
                <Link
                  underline={false}
                  variant="white"
                  href="http://ult.kemdikbud.go.id/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block !pl-0"
                >
                  <Text variant="action">Unit Layanan Terpadu (ULT)</Text>
                </Link>
                <div>
                  <Text variant="body-sm" color="subdued">
                    Gedung C Lantai 1 Kompleks Kemdikbud Senayan Jakarta, 10270
                  </Text>
                  <Text variant="body-sm" color="subdued">
                    Email:{' '}
                    <Link
                      href="mailto:pengaduan@kemdikbud.go.id"
                      underline={false}
                      style={{ color: 'inherit' }}
                    >
                      pengaduan@kemdikbud.go.id
                    </Link>
                  </Text>
                </div>
              </div>
              <div className="space-y-2">
                <Link
                  underline={false}
                  variant="white"
                  href="https://pusdatin.kemdikbud.go.id/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="block !pl-0"
                >
                  <Text variant="action">
                    Pusat Data dan Teknologi Informasi
                  </Text>
                </Link>
                <div>
                  <Text variant="body-sm" color="subdued">
                    Jl. RE. Martadinata KM. 15.5 Ciputat, Tangerang Selatan.
                    15411
                  </Text>
                  <Text variant="body-sm" color="subdued">
                    Contact Center: 1500 005
                  </Text>

                  <Text variant="body-sm" color="subdued">
                    Telp: 021 741 8808
                  </Text>

                  <Text variant="body-sm" color="subdued">
                    Fax: 021 740 1727
                  </Text>

                  <Text variant="body-sm" color="subdued">
                    Email:{' '}
                    <Link
                      href="mailto:pusdatin@kemdikbud.go.id"
                      underline={false}
                      style={{ color: 'inherit' }}
                    >
                      pusdatin@kemdikbud.go.id
                    </Link>
                  </Text>
                </div>
              </div>
            </div>
            {/* Center Section --end */}

            {/* Right Section --start */}
            <div>
              <Text variant="heading-md">Tautan Terkait</Text>
              <div className="mt-6">
                <ul className="flex flex-col gap-1">
                  {TAUTAN_TERKAIT.map((item) => (
                    <li key={item.text} className="relative">
                      <Text variant="action" color="subdued">
                        <Link
                          underline={false}
                          style={{ color: 'inherit' }}
                          href={item.link}
                          rel="noopener noreferrer"
                          target="_blank"
                          className="text-sub block !py-1 text-sm"
                        >
                          {item.text}
                        </Link>
                      </Text>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            {/* Right Section --end */}
          </div>
        </Container>

        <div className="w-full border-t bg-blue-90 py-6">
          <Container>
            {clicked && <EasterEgg />}
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <button
                {...bindDoubleTap}
                type="button"
                className="___double-tap-me z-[2] cursor-pointer py-2 pl-0 md:text-left"
              >
                <Text color="inherit" variant="body-sm">
                  Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi
                  Republik Indonesia © {new Date().getFullYear()}
                </Text>
              </button>
              <button
                type="button"
                className="z-[2] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full focus:ring-2 focus:ring-blue-70 focus:ring-offset-2"
                onClick={handleToTop}
                aria-label="Kembali ke atas"
              >
                <Icon color="inverse" fontSize="sm">
                  arrow_upward
                </Icon>
              </button>
            </div>
          </Container>
        </div>
      </footer>
    </>
  )
}
