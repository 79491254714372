'use client'

import { scrollToTop } from '@/utils/dom-access'

import { LazyImg } from './LazyImg'

export const BackToTop = () => {
  const handleToTop = () => {
    scrollToTop()
  }

  return (
    <section className="border-t py-8" id="backtotop-section">
      <div className="flex items-center justify-center">
        <button
          role="button"
          aria-disabled="false"
          data-testid="btn-back-to-top"
          aria-label="Kembali ke atas"
          onClick={handleToTop}
          className="z-[1]"
        >
          <LazyImg
            src="/images/logo-kemendikbud-gray.png"
            alt="Kembali ke atas"
            withWebp={true}
            useCdn={true}
            height={40}
            width={40}
            className="h-auto max-h-[40px] w-auto cursor-pointer"
          />
        </button>
      </div>
    </section>
  )
}
