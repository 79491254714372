'use client'

'use client'

import { PropsWithChildren } from 'react'

import {
  dehydrate,
  HydrationBoundary,
  QueryClient,
} from '@tanstack/react-query'

function Hydrate({
  children,
  client,
}: PropsWithChildren<{ client: QueryClient }>) {
  return (
    <HydrationBoundary state={dehydrate(client)}>{children}</HydrationBoundary>
  )
}

export default Hydrate
