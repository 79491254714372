'use client'

import styles from './easter-egg-styles.module.css'

import clsx from 'clsx'

import { Text } from '@wartek-id/text'

import { range } from '@/utils/array'

import {
  APP_SEMANTIC_VERSION,
  APP_SHORT_VERSION,
  APP_VERSION,
  BUILD_UTC_TIME,
} from '@/configs/constants'

import { KeyValTable } from '../KeyValTable'

export function EasterEgg() {
  return (
    <>
      <div className="z-[2] space-y-4">
        <div className="text-left">
          <Text color="inherit" variant="heading-sm">
            APP INFO
          </Text>
        </div>
        <div className="w-full">
          <KeyValTable
            bordered={true}
            classNames={{
              table: '',
              body: styles.tableBody,
            }}
            rows={[
              {
                key: 'env',
                displayName: 'ENV',
                value: `${process.env.NEXT_PUBLIC_ENV || 'Unidentified env'}`,
              },
              {
                key: 'version',
                displayName: 'Version',
                value: APP_SEMANTIC_VERSION,
              },
              {
                key: 'short-version',
                displayName: 'Short Version',
                value: APP_SHORT_VERSION,
              },
              {
                key: 'long-version',
                displayName: 'Long Version',
                value: APP_VERSION,
              },
              {
                key: 'deployed-at',
                displayName: 'Last Deployed At',
                value: `${BUILD_UTC_TIME || 'Can not detected'}`,
              },
            ]}
          />
        </div>
      </div>
      <div className={clsx('relative bg-blue-90', styles.confetti)}>
        {range(0, 50).map((item) => {
          return <div className={styles.confettiItem} key={item}></div>
        })}
      </div>
    </>
  )
}
