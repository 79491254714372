'use client'

import Script from 'next/script'
import { createContext, PropsWithChildren, useContext } from 'react'

export const BotContext = createContext(false)

export const DynamicScriptWithBotDetector = ({ isBot }: { isBot: boolean }) => {
  return (
    <>
      {process.env.NEXT_PUBLIC_GA_ID && !isBot ? (
        <>
          <Script
            id="gtm-instance"
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_ID}`}
          />
          <Script id="gtag-instance">
            {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', '${process.env.NEXT_PUBLIC_GA_ID}', {
              page_path: window.location.pathname
            });
          `}
          </Script>
        </>
      ) : null}

      <Script id="bot-detector">{`window.__isBot = ${isBot} || false;`}</Script>
    </>
  )
}

export const BotProvider = ({
  value,
  children,
}: PropsWithChildren<{ value: boolean }>) => {
  return (
    <BotContext.Provider value={value}>
      <DynamicScriptWithBotDetector isBot={value} />
      {children}
    </BotContext.Provider>
  )
}

export const useBotContext = () => {
  const isBot = useContext(BotContext)

  return {
    isBot,
  }
}
