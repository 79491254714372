import { ReactNode } from 'react'

import {
  DATA_INDUK_PTK_ROUTE,
  DATA_INDUK_ROUTE,
  DATA_INDUK_YAYASAN_ROUTE,
  DATASET_ROUTE,
  HOME_ROUTE,
  KEMENDIKBUD_URL,
  METADATA_ROUTE,
  PUBLIKASI_ROUTE,
  ROOT_DASHBOARD_PRIORITAS_ROUTE,
  ROOT_RAPORPUBLIK_ROUTE,
  UTM_SOURCE_FROM_PSD,
} from '@/configs/constants'

import { DataIndukIcon } from '../Icons/DataIndukIcon'

export interface MenuChildItem {
  text: string
  href: string
  index: number
}

export interface NewMenuItem {
  text: string
  href: string
  index: number
  new?: boolean
  specialVisual?: boolean
  desc?: string
  descLogo?: ReactNode
  children?: MenuChildItem[]
}

export function getDataMenus({ withPtk }: { withPtk: boolean }): NewMenuItem[] {
  return [
    {
      text: 'Beranda',
      href: HOME_ROUTE,
      index: 0,
    },
    {
      text: 'Dataset',
      href: DATASET_ROUTE,
      index: 1,
    },
    {
      text: 'Data Induk',
      href: DATA_INDUK_ROUTE,
      index: 2,
      descLogo: <DataIndukIcon />,
      desc: 'Berisi data Pauddikdasmen, Yayasan, dan Pendidik dan Tenaga Kependidikan.',
      children: [
        {
          text: 'Satuan Pendidikan',
          href: DATA_INDUK_ROUTE,
          index: 211,
        },
        {
          text: 'Badan Penyelenggara (Yayasan)',
          href: DATA_INDUK_YAYASAN_ROUTE,
          index: 212,
        },
        withPtk
          ? {
              text: 'Pendidik dan Tenaga Kependidikan',
              href: DATA_INDUK_PTK_ROUTE,
              index: 213,
            }
          : null,
      ].filter(Boolean) as MenuChildItem[],
    },
    {
      text: 'Publikasi',
      href: PUBLIKASI_ROUTE,
      index: 3,
    },
    {
      text: 'Platform Prioritas',
      href: ROOT_DASHBOARD_PRIORITAS_ROUTE,
      index: 4,
    },
    {
      text: 'Platform Pendukung',
      href: `${KEMENDIKBUD_URL}/`,
      index: 5,
      specialVisual: true, // to support custom children
    },
  ]
}

export const menuGroupDapodik: MenuChildItem[] = [
  {
    text: 'Dapodik',
    href: 'https://dapo.kemdikbud.go.id',
    index: 511,
  },
  {
    text: 'Dapodik LKP',
    href: 'https://app.vokasi.kemdikbud.go.id/dapodik?' + UTM_SOURCE_FROM_PSD,
    index: 521,
  },
  {
    text: 'Management Dapodik LKP',
    href: 'https://manajemen.vokasi.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 522,
  },
  {
    text: 'PDDIKTI',
    href: 'https://pddikti.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 531,
  },
]

export const menuGroupVerval: MenuChildItem[] = [
  {
    text: 'Verval Yayasan',
    href: 'https://vervalyayasan.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 611,
  },
  {
    text: 'Verval Satuan Pendidikan',
    href: 'https://vervalsp.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 612,
  },
  {
    text: 'Verval PTK',
    href: 'https://vervalptk.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 613,
  },
  {
    text: 'Verval Peserta Didik',
    href: 'https://vervalpd.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
    index: 614,
  },
  {
    text: 'Verval Kebudayaan',
    href: `${KEMENDIKBUD_URL}/#`,
    index: 615,
  },
  {
    text: 'Verval Bahasa dan Sastra',
    href: `${KEMENDIKBUD_URL}/#`,
    index: 616,
  },
]

export function getMenuOthers({
  withRaporPublik,
}: {
  withRaporPublik: boolean
}): MenuChildItem[] {
  return [
    {
      text: 'Metadata',
      href: METADATA_ROUTE,
      index: 711,
    },
    withRaporPublik
      ? {
          text: 'Rapor Pendidikan untuk Publik',
          href: ROOT_RAPORPUBLIK_ROUTE,
          index: 712,
        }
      : null,
    {
      text: 'Referensi',
      href: 'https://referensi.data.kemdikbud.go.id/?' + UTM_SOURCE_FROM_PSD,
      index: 713,
    },
  ].filter(Boolean) as MenuChildItem[]
}

export function getPlatfromPendukungMenus({
  withRaporPublik,
}: {
  withRaporPublik: boolean
}) {
  return [
    {
      text: 'Pendataan',
      id: 'pendataan',
      desc: 'Pengumpulan, pengorganisasian, dan pencatatan informasi',
      children: menuGroupDapodik,
    },
    {
      text: 'E-Verval',
      id: 'everval',
      desc: 'Proses verifikasi atau konfirmasi yang dilakukan secara elektronik',
      children: menuGroupVerval,
    },
    {
      text: 'Lainnya',
      id: 'lainnya',
      desc: '',
      children: getMenuOthers({ withRaporPublik }),
    },
  ]
}
