'use client'

import { usePathname } from 'next/navigation'

import { ROOT_RAPORPUBLIK_ROUTE } from '@/configs/constants'

import { FooterDefault } from './FooterDefault'
import { FooterRaporPublik } from './FooterRaporPublik'

export const Footer = () => {
  const pathname = usePathname()

  return (
    <>
      {pathname.includes(ROOT_RAPORPUBLIK_ROUTE) ? (
        <FooterRaporPublik />
      ) : (
        <FooterDefault />
      )}
    </>
  )
}
