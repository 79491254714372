import {
  DATA_INDUK_PTK_ROUTE,
  DATA_INDUK_ROUTE,
  DATA_INDUK_YAYASAN_ROUTE,
  DATASET_ROUTE,
  PUBLIKASI_ROUTE,
} from '@/configs/constants'

import { getDataMenus, NewMenuItem } from './menus'

export function useActiveMenu({ currentPath }: { currentPath: string }) {
  const menu = getDataMenus({ withPtk: true })

  const activeMenu: NewMenuItem | undefined = menu.find((menu: NewMenuItem) => {
    if (currentPath.indexOf(PUBLIKASI_ROUTE) >= 0) {
      return menu.href === PUBLIKASI_ROUTE
    }

    if (currentPath.indexOf(DATASET_ROUTE) >= 0) {
      return menu.href === DATASET_ROUTE
    }

    if (currentPath.indexOf(DATA_INDUK_ROUTE) >= 0) {
      return menu.href === DATA_INDUK_ROUTE
    }

    return menu.href === currentPath
  })

  const activeIndex: number = activeMenu?.index ?? 999

  return {
    activeIndex,
    activeMenu,
  }
}

export const isDataIndukPath = (currentPath: string) => {
  return currentPath.indexOf(DATA_INDUK_ROUTE) >= 0
}

export const isDataIndukYayasanPath = (currentPath: string) => {
  return currentPath.indexOf(DATA_INDUK_YAYASAN_ROUTE) >= 0
}

export const isDataIndukPtkPath = (currentPath: string) => {
  return currentPath.indexOf(DATA_INDUK_PTK_ROUTE) >= 0
}

export function isActiveChildren({
  currentPath,
  parentMenu,
  childMenu,
}: {
  currentPath: string
  parentMenu: NewMenuItem
  childMenu: NewMenuItem
}) {
  if (parentMenu.href === DATA_INDUK_ROUTE && isDataIndukPath(currentPath)) {
    if (
      childMenu.href === DATA_INDUK_YAYASAN_ROUTE &&
      isDataIndukYayasanPath(currentPath)
    ) {
      return true
    } else if (
      childMenu.href === DATA_INDUK_PTK_ROUTE &&
      isDataIndukPtkPath(currentPath)
    ) {
      return true
    } else if (
      childMenu.href === DATA_INDUK_ROUTE &&
      !isDataIndukYayasanPath(currentPath) &&
      !isDataIndukPtkPath(currentPath)
    ) {
      return true
    }
  }

  return false
}
