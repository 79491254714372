'use client'

import Script from 'next/script'
import { createContext, PropsWithChildren, useContext } from 'react'

export const SessionContext = createContext('')

export const WithSessionIdGlobalVar = ({
  sessionId,
}: {
  sessionId: string
}) => {
  return (
    <>
      <Script id="sid">{`window.__DPSID = "${sessionId}" || "";`}</Script>
    </>
  )
}

export const SessionProvider = ({
  sessionId,
  children,
}: PropsWithChildren<{ sessionId: string }>) => {
  return (
    <SessionContext.Provider value={sessionId}>
      <WithSessionIdGlobalVar sessionId={sessionId} />
      {children}
    </SessionContext.Provider>
  )
}

export const useSessionId = () => {
  const sessionId = useContext(SessionContext)

  return {
    sessionId,
  }
}
