'use client'

import { useEffect } from 'react'

import { initPoolinScheduleSatuEvent } from '@/utils/tracker/satuTracker'

export function InitSatuEventTracker() {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      initPoolinScheduleSatuEvent()
    }
  }, [])

  return null
}
