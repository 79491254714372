'use client'

import clsx from 'clsx'
import { usePathname } from 'next/navigation'
import { useState } from 'react'

import { Icon } from '@wartek-id/icon'
import { useMedia } from '@wartek-id/react-utils'

import { HeaderLogoNew } from './HeaderLogoNew'
import { HeaderMenuDesktopNew } from './HeaderMenuDesktopNew'
import { HeaderMenuMobileNew } from './HeaderMenuMobileNew'
import { Container, DefaultContainerSpacingAsPadding } from '../Container'

export const HeaderApp = ({ withMenu = false }) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const pathname = usePathname()
  const isMd = useMedia('(min-width: 768px)', false)

  return (
    <>
      <header
        id="header-app"
        className={clsx(
          'fixed left-0 top-0 z-30 h-[65px] w-full transform border-b bg-white transition-all duration-500'
        )}
      >
        <Container removePadding={true}>
          <div
            className={clsx(
              'flex h-[65px] items-center justify-between gap-4',
              DefaultContainerSpacingAsPadding
            )}
          >
            <HeaderLogoNew />

            {withMenu && !isMd && (
              <>
                <div>
                  <div
                    className={clsx(
                      'flex h-[36px] w-[36px] items-center justify-center rounded'
                    )}
                    role="button"
                    onClick={() => {
                      setMenuOpen(true)
                    }}
                    aria-label="Toggle Menu"
                    data-testid={`btn-toggle-menu`}
                  >
                    {isMenuOpen ? (
                      <Icon color={'default'}>close</Icon>
                    ) : (
                      <Icon color={'default'}>menu</Icon>
                    )}
                  </div>
                </div>
              </>
            )}

            {withMenu ? (
              <>
                {isMd && <HeaderMenuDesktopNew currentPath={pathname || ''} />}
              </>
            ) : null}
          </div>
        </Container>
      </header>

      {!isMd && withMenu ? (
        <>
          <HeaderMenuMobileNew
            isOpen={isMenuOpen}
            setOpen={setMenuOpen}
            currentPath={pathname || ''}
          />
        </>
      ) : null}
    </>
  )
}
