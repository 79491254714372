'use client'

import style from './header-menu.module.css'

import clsx from 'clsx'
import { Dispatch, Fragment, SetStateAction } from 'react'

import { Badge } from '@wartek-id/badge'
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleIcon,
  CollapsiblePlusIcon,
  CollapsibleTrigger,
} from '@wartek-id/collapsible'
import { Icon } from '@wartek-id/icon'
import { List, ListItem } from '@wartek-id/list'
import {
  Sheet,
  SheetBody,
  SheetCloseButton,
  SheetContent,
  SheetOverlay,
  SheetTitle,
} from '@wartek-id/sheet'
import { Text } from '@wartek-id/text'

import { FLAG_KEY_RAPOR_PUBLIK } from '@/utils/toggle'

import { DATA_INDUK_ROUTE } from '@/configs/constants'

import { getDataMenus, getPlatfromPendukungMenus } from './menus'
import {
  isActiveChildren,
  isDataIndukPath,
  useActiveMenu,
} from './useActiveMenu'
import { LinkWithTracker } from '../LinkWithTracker'
import { useIsActiveFF } from '../WhitelistFfContext'

export const HeaderMenuMobileNew = ({
  isOpen,
  setOpen,
  // eslint-disable-next-line unused-imports/no-unused-vars
  currentPath,
}: {
  isOpen: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  currentPath: string
}) => {
  const { activeIndex } = useActiveMenu({ currentPath })

  const { isActive: isRaporPublikActive } = useIsActiveFF({
    code: FLAG_KEY_RAPOR_PUBLIK,
    envVal: process.env.NEXT_PUBLIC_FEATURE_RAPOR_PUBLIK,
  })

  const menus = getDataMenus({ withPtk: true })
  const platformPendukungMenus = getPlatfromPendukungMenus({
    withRaporPublik: isRaporPublikActive,
  })

  return (
    <Sheet
      type="modal"
      autoFocus={false}
      position="right"
      isOpen={isOpen}
      onClose={setOpen}
    >
      <SheetOverlay />
      <SheetContent>
        <SheetTitle bordered bold>
          Portal Data Kemendikbudristek
        </SheetTitle>
        <SheetBody className="p-4">
          {menus.map((menu, index) => {
            const isDataIndukMenu = menu.href === DATA_INDUK_ROUTE
            const isOnDataInduk = isDataIndukPath(currentPath)
            const defaultOpen = isDataIndukMenu && isOnDataInduk ? true : false

            return (
              <Fragment key={menu.text}>
                {menu?.children && menu?.children?.length > 0 ? (
                  <Collapsible defaultOpen={defaultOpen}>
                    <CollapsibleTrigger
                      className={clsx(style.trigger)}
                      data-testid={`menu-${menu.index}`}
                    >
                      <span>{menu.text}</span>
                      <CollapsibleIcon />
                    </CollapsibleTrigger>
                    <CollapsibleContent>
                      {menu.desc && (
                        <div
                          className={clsx(
                            'mb-2 space-y-2 px-6 py-2',
                            style.mobileMenuLogo
                          )}
                        >
                          {menu.descLogo ? menu.descLogo : null}
                          <Text variant="heading-sm">{menu.text}</Text>
                          <Text color="subdued">{menu.desc}</Text>
                        </div>
                      )}

                      <List type="menu" className="!ml-4">
                        {menu?.children?.map((childMenu) => {
                          const isActive = isActiveChildren({
                            currentPath,
                            parentMenu: menu,
                            childMenu,
                          })
                          const isExternalUrl =
                            childMenu.href.indexOf('http') >= 0
                          return (
                            <ListItem
                              key={childMenu.text}
                              as="a"
                              href={childMenu.href}
                              target={isExternalUrl ? '_blank' : undefined}
                              rel={
                                isExternalUrl
                                  ? 'noopener noreferrer'
                                  : undefined
                              }
                              data-testid={`child-menu-${childMenu.index}`}
                              iconRight={
                                isActive ? (
                                  <Icon color="informational">check</Icon>
                                ) : null
                              }
                            >
                              <Text variant="body-sm">{childMenu.text}</Text>
                            </ListItem>
                          )
                        })}
                      </List>
                    </CollapsibleContent>
                  </Collapsible>
                ) : (
                  <>
                    {menu.specialVisual ? (
                      <Collapsible defaultOpen={defaultOpen}>
                        <CollapsibleTrigger
                          className={clsx(style.trigger)}
                          data-testid={`menu-${menu.index}`}
                        >
                          <span>{menu.text}</span>
                          <CollapsibleIcon />
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                          {platformPendukungMenus.map((menuPendukung) => (
                            <Collapsible
                              defaultOpen={defaultOpen}
                              className="ml-2"
                              key={menuPendukung.text}
                            >
                              <CollapsibleTrigger
                                className={clsx(style.trigger)}
                                data-testid={`menu-pendukung-${menuPendukung.id}`}
                              >
                                <span>{menuPendukung.text}</span>
                                <CollapsiblePlusIcon />
                              </CollapsibleTrigger>
                              <CollapsibleContent>
                                {menuPendukung.desc && (
                                  <div
                                    className={clsx(
                                      'mb-1 mt-2 space-y-2 px-6',
                                      style.mobileMenuLogo
                                    )}
                                  >
                                    <Text color="subdued">
                                      {menuPendukung.desc}
                                    </Text>
                                  </div>
                                )}

                                <List type="menu" className="!ml-4">
                                  {menuPendukung?.children?.map((childMenu) => {
                                    return (
                                      <ListItem
                                        key={childMenu.text}
                                        as="a"
                                        href={childMenu.href}
                                        data-testid={`child-menu-${childMenu.index}`}
                                      >
                                        <Text variant="body-sm">
                                          {childMenu.text}
                                        </Text>
                                      </ListItem>
                                    )
                                  })}
                                </List>
                              </CollapsibleContent>
                            </Collapsible>
                          ))}
                        </CollapsibleContent>
                      </Collapsible>
                    ) : (
                      <LinkWithTracker
                        href={menu.href}
                        passHref
                        prefetch={false}
                        className={clsx(
                          style.mobileMenu,
                          index === activeIndex && 'is--active',
                          'flex items-center justify-between gap-1 rounded border-b p-[12px] hover:bg-surface-subdued'
                        )}
                        onClick={() => {
                          setOpen(false)
                        }}
                      >
                        <span>{menu.text}</span>
                        {menu.new && (
                          <Badge variant="informational">Baru</Badge>
                        )}
                      </LinkWithTracker>
                    )}
                  </>
                )}
              </Fragment>
            )
          })}
        </SheetBody>
        <SheetCloseButton onClick={() => setOpen(false)} />
      </SheetContent>
    </Sheet>
  )
}
