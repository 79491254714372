import(/* webpackMode: "eager" */ "/builds/wartek-id/data-pipeline/data-portal-ui/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["BotProvider"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/BotDetector/BotProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Footer"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeaderApp"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/Header/HeaderApp.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InitSatuEventTracker"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/InitSatuEventTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/SessionContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WhitelistFfProvider"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/components/WhitelistFfContext.tsx");
;
import(/* webpackMode: "eager" */ "/builds/wartek-id/data-pipeline/data-portal-ui/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/wartek-id/data-pipeline/data-portal-ui/utils/react-query/QueryProvider.tsx");
