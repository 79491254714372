/**
 * Auto redirection from dashboard to data.kemdikbud.go.id
 */
export const FLAG_KEY_DAVI_1163 = 'DAVI-1163'

/**
 * Rapor Publik
 */
export const FLAG_KEY_RAPOR_PUBLIK = 'RP'

/**
 * New PDF Viewer in Publication Page
 */
export const FLAG_KEY_NEW_PDF_VIEWER = 'NEW_PDF'

/**
 * https://wartek.atlassian.net/browse/DAVI-1503
 * Dowload feature in data induk satpen level kab kot
 */
export const FLAG_KEY_DAVI_1503 = 'DAVI-1503'

export const isContainsWhitelist = (
  cookieVal: string[],
  flagKey: string
): boolean => {
  if (Array.isArray(cookieVal)) {
    return cookieVal.includes(flagKey)
  }

  return false
}
