'use client'

import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react'

import { isContainsWhitelist } from '@/utils/toggle'

export const WhitelistFfContext = createContext<{
  whitelisted: string[]
  isWhitelistedFn: (flag: string) => boolean
}>({
  whitelisted: [],
  isWhitelistedFn: (_flag: string) => false,
})

export const WhitelistFfProvider = ({
  whitelistedFFs,
  children,
}: PropsWithChildren<{ whitelistedFFs: string[] }>) => {
  const isWhitelistedFn = useCallback(
    (flag: string) => {
      return isContainsWhitelist(whitelistedFFs, flag)
    },
    [whitelistedFFs]
  )

  return (
    <WhitelistFfContext.Provider
      value={{
        whitelisted: Array.isArray(whitelistedFFs) ? whitelistedFFs : [],
        isWhitelistedFn,
      }}
    >
      {children}
    </WhitelistFfContext.Provider>
  )
}

export const useWhitelistFf = () => {
  return useContext(WhitelistFfContext)
}

export const useIsActiveFF = ({
  code,
  envVal = 'n',
}: {
  code: string
  envVal?: string
}) => {
  const { isWhitelistedFn } = useWhitelistFf()

  const isActive: boolean = useMemo(() => {
    return isWhitelistedFn(code) || envVal === 'y'
  }, [isWhitelistedFn, envVal, code])

  return {
    isActive: isActive,
  }
}
