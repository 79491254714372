'use client'

import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { noop } from '@/utils/fn'

import { ASSET_PREFIX, PLACEHOLDER_SRC } from '@/configs/constants'

const PREFIX_REGEX = /.(png|jpg|jpeg|gif|bmp)$/gi

interface LazyImgProps {
  src: string
  fallbackSrc?: string
  alt: string
  type?: 'png' | 'jpg' | 'jpeg'
  height: number
  width: number
  className?: string
  withWebp?: boolean
  useCdn?: boolean
  onClick?: () => void
  onLoaded?: () => void
}

export function LazyImg({
  src,
  fallbackSrc = '',
  alt,
  className = '',
  type = 'png',
  height,
  width,
  withWebp = false,
  useCdn = false,
  onClick = noop,
  onLoaded = noop,
}: LazyImgProps) {
  const fullSrc = useCdn ? ASSET_PREFIX + src : src
  const fullFallbackSrc = useCdn ? ASSET_PREFIX + fallbackSrc : fallbackSrc

  const { ref: refImage, inView: isInView } = useInView({
    triggerOnce: true,
  })

  const [imgSrc, setImgSrc] = useState(PLACEHOLDER_SRC)

  const onErrorImage = useCallback(() => {
    if (fallbackSrc) {
      const img = new Image()
      img.onerror = () => {
        setImgSrc(PLACEHOLDER_SRC)
      }
      img.onload = onLoaded

      img.src = fullFallbackSrc
      setImgSrc(fullFallbackSrc)
    } else {
      setImgSrc(PLACEHOLDER_SRC)
    }
  }, [fallbackSrc, fullFallbackSrc, onLoaded])

  useEffect(() => {
    if (isInView) {
      const img = new Image()
      img.onerror = onErrorImage
      img.onload = onLoaded

      img.src = fullSrc
      setImgSrc(fullSrc)
    }
  }, [fullSrc, isInView, onLoaded, onErrorImage])

  return (
    <picture
      ref={refImage}
      onClick={onClick}
      className={imgSrc !== PLACEHOLDER_SRC ? 'img--loaded' : 'img--loading'}
    >
      {withWebp && imgSrc !== '' && (
        <source
          type="image/webp"
          srcSet={imgSrc.replace(PREFIX_REGEX, '.webp')}
        />
      )}
      <source type={`image/${type}`} srcSet={imgSrc} />
      <img
        src={imgSrc}
        data-url={imgSrc === PLACEHOLDER_SRC ? fullSrc : ''}
        alt={alt}
        className={className}
        height={height}
        width={width}
        loading="lazy"
      />
    </picture>
  )
}
