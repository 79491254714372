import clsx from 'clsx'

interface ContainerProps<T extends React.ElementType> {
  as?: T
  children?: React.ReactNode
  className?: string
  removePadding?: boolean
}

export const DefaultContainerSpacingAsMargin = 'mx-6 md:mx-8'
export const DefaultContainerSpacingAsPadding = 'px-6 md:px-8'
export const BaseContainerClassName = 'psdContainer mx-auto'

export function Container<T extends React.ElementType = 'div'>({
  as,
  className = '',
  removePadding = false,
  children,
  ...restProps
}: ContainerProps<T> &
  Omit<React.ComponentPropsWithoutRef<T>, keyof ContainerProps<T>>) {
  const Component = as || 'div'
  return (
    <Component
      className={clsx(
        'psdContainer mx-auto',
        !removePadding ? DefaultContainerSpacingAsPadding : '',
        className
      )}
      {...restProps}
    >
      {children}
    </Component>
  )
}
