export const HEADER_OFFSET = 65

export const scrollToTop = () => {
  if (window.scrollTo) {
    requestAnimationFrame(() => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    })
  }
}

export const scrollToDomId = (id: string, additionalSpacing = 0) => {
  const element = document.getElementById(id)

  if (!!element) {
    const elementPosition = element.getBoundingClientRect().top
    const topSpacing = HEADER_OFFSET + additionalSpacing
    const offsetPosition = elementPosition + window.pageYOffset - topSpacing

    requestAnimationFrame(() => {
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    })
  }
}
