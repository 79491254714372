'use client'

import clsx from 'clsx'
import { useParams } from 'next/navigation'
import { useState } from 'react'
import { useDoubleTap } from 'use-double-tap'

import { Icon } from '@wartek-id/icon'
import { Text } from '@wartek-id/text'

import { scrollToTop } from '@/utils/dom-access'

import { Container } from '../Container'
import { EasterEgg } from '../EasterEgg/EasterEgg'

export const FooterRaporPublik = () => {
  const params = useParams()
  const year = (params?.year as string) || ''

  const [clicked, setClicked] = useState(false)

  const bindDoubleTap = useDoubleTap(() => {
    setClicked((prev) => !prev)
  })

  const handleToTop = () => {
    scrollToTop()
  }

  return (
    <footer className={clsx('relative bg-blue-10 text-white')}>
      <div className="h-[6px] w-full bg-orange-20"></div>
      <div
        className={clsx(
          'w-full border-t bg-blue-90 pt-6 md:pb-6',
          year ? 'pb-20' : 'pb-6'
        )}
      >
        <Container>
          {clicked && <EasterEgg />}
          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <button
              {...bindDoubleTap}
              type="button"
              className="___double-tap-me z-[2] cursor-pointer py-2 pl-0 md:text-left"
            >
              <Text color="inherit" variant="body-sm">
                Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi
                Republik Indonesia © {new Date().getFullYear()}
              </Text>
            </button>
            <button
              type="button"
              className="z-[2] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full focus:ring-2 focus:ring-blue-70 focus:ring-offset-2"
              onClick={handleToTop}
              aria-label="Kembali ke atas"
            >
              <Icon color="inverse" fontSize="sm">
                arrow_upward
              </Icon>
            </button>
          </div>
        </Container>
      </div>
    </footer>
  )
}
