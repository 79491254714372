'use client'

import clsx from 'clsx'

import { Icon } from '@wartek-id/icon'
import { List, ListItem } from '@wartek-id/list'
import { Text } from '@wartek-id/text'

import { FLAG_KEY_RAPOR_PUBLIK } from '@/utils/toggle'

import { getPlatfromPendukungMenus } from './menus'
import { useIsActiveFF } from '../WhitelistFfContext'

export const MenuPlatformPendukung = () => {
  const { isActive: isRaporPublikActive } = useIsActiveFF({
    code: FLAG_KEY_RAPOR_PUBLIK,
    envVal: process.env.NEXT_PUBLIC_FEATURE_RAPOR_PUBLIK,
  })

  const platformPendukungMenus = getPlatfromPendukungMenus({
    withRaporPublik: isRaporPublikActive,
  })

  return (
    <div className={clsx('grid grid-cols-4 bg-white')}>
      {platformPendukungMenus.map((menuPendukung) => (
        <div className={clsx('border-r p-8')} key={menuPendukung.text}>
          <div className="mb-4 space-y-2 p-2">
            <Text variant="heading-md">{menuPendukung.text}</Text>
            <Text className="max-w-[300px]">{menuPendukung.desc}</Text>
          </div>
          <List type="menu" className="p-2">
            {menuPendukung?.children?.map((childMenu) => {
              const isExternalUrl = childMenu.href.indexOf('http') >= 0
              return (
                <ListItem
                  key={childMenu.index}
                  as="a"
                  href={childMenu.href}
                  target={isExternalUrl ? '_blank' : undefined}
                  rel={isExternalUrl ? 'noopener noreferrer' : undefined}
                  data-testid={`child-menu-${childMenu.index}`}
                  iconRight={<Icon>chevron_right</Icon>}
                >
                  <Text variant="body-bold">{childMenu.text}</Text>
                </ListItem>
              )
            })}
          </List>
        </div>
      ))}
    </div>
  )
}
